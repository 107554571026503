/**
 * 策划面对面API
 * */
import { gameplusApi } from '@/util/http';

function getWelfareInfo({
  gid = '', pid = '', page = 1, pageSize = 10,
} = {}) {
  return gameplusApi.request({
    url: '/community.GameToolSrv/GetWelfarePageInfo',
    data: {
      gid,
      pid,
      page,
      page_size: pageSize,
    },
    options: {
      errorHandler: false,
    },
    // mock: ftfHistory,
  });
}

function sharePageAddExp({ pid = 0 } = {}) {
  return gameplusApi.request({
    url: '/community.GameToolSrv/SharePageAddExp',
    data: {
      pid,
    },
    options: {
      errorHandler: false,
    },
    // mock: ftfHistory,
  });
}

function getGameGiftPkgNum() {
  return gameplusApi.request({
    url: '/community.GiftSrv/GetGameGiftPkgNum',
    data: {},
    options: {
      errorHandler: false,
    },
    // mock: ftfHistory,
  });
}

/**
 * 拉取内容分享页展示的礼包
 * @param {String} gameCode 游戏标识
 * @returns {Object}
 */
function getContentShareGifts(gameCode) {
  return gameplusApi.request({
    url: '/community.GiftSrv/GetContentShareGifts',
    data: {
      game_code: gameCode,
    },
    options: {
      errorHandler: false,
    },
    // mock: ftfHistory,
  });
}


export {
  // eslint-disable-next-line import/prefer-default-export
  getWelfareInfo,
  sharePageAddExp,
  getGameGiftPkgNum,
  getContentShareGifts,
};
