<template>
  <!--<transition name="ui-dialog-fade">-->
  <div
    v-if="show"
    class="ui-dialog"
  >
    <div
      @click="handlerClose"
      class="overlay"
    />
    <div
      :class="type"
      class="ui-dialog__box"
    >
      <div
        v-if="type === 'celebrate'"
        class="ui-dialog__celebrate-bg"
      />
      <div
        v-if="type === 'celebrate'"
        class="ui-dialog__celebrate-icon"
      />
      <div class="ui-dialog__box-inner">
        <div
          class="ui-dialog-header"
        >
          <slot name="header" />
        </div>
        <div
          :style="contentStyle"
          class="ui-dialog-content"
        >
          <slot>
            <div
              v-if="type === 'success'"
              class="icon-success"
            />
            <div
              v-else-if="type === 'fail'"
              class="icon-fail"
            />
            <div
              v-if="contentTitle"
              :style="{marginBottom: contentText ? '6px' : 0}"
              class="ui-dialog-content-title"
            >
              {{ contentTitle }}
            </div>
            <div
              v-if="contentText"
              :class="{'only-text': !contentTitle}"
              class="ui-dialog-content-text"
            >
              {{ contentText }}
            </div>
          </slot>
        </div>
        <div class="ui-dialog-footer">
          <slot name="footer">
            <div
              v-if="confirm"
              class="footer-button-wrapper ui-border-t"
            >
              <div
                :style="cancelTextStyle"
                @click.stop="handlerCancel"
                class="footer-button button-cancel"
              >
                {{ cancelText }}
              </div>
              <div
                v-if="showConfirmBtn"
                :style="confirmTextStyle"
                @click.stop="handlerConfirm"
                class="footer-button button-confirm ui-border-l"
              >
                {{ confirmText }}
              </div>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
<!--</transition>-->
</template>

<script>
export default {
  name: 'Dialog',
  model: {
    prop: 'show',
    event: 'change',
  },
  props: {
    type: {
      type: String,
      default: 'default',
      validator(val) {
        return ['success', 'fail', 'warn', 'default', 'celebrate'].indexOf(val) > -1;
      },
    },
    show: {
      type: Boolean,
      default: false,
    },
    contentTitle: {
      type: [String, Number],
      default: '',
    },
    contentText: {
      type: [String, Number],
      default: '',
    },
    confirm: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default: '取消',
    },
    confirmText: {
      type: String,
      default: '确定',
    },
    cancelTextStyle: {
      type: Object,
      default: () => ({}),
    },
    confirmTextStyle: {
      type: Object,
      default: () => ({}),
    },
    contentStyle: {
      type: Object,
      default: () => ({}),
    },
    confirmClose: { // 点击确认是否关闭
      type: Boolean,
      default: true,
    },
    showConfirmBtn: { // confirm模式是否展示confrim按钮，用于只展示一个按钮的场景
      type: Boolean,
      default: true,
    },
  },
  watch: {
    show(val) {
      // 解决滚动穿透问题
      if (val) {
        const scrollTop = this.getScrollOffset().y;
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.top = `${-scrollTop}px`;
      } else {
        const scrollTop = document.body.style.top.replace('-', '').replace('px', '');
        document.body.style.overflow = 'auto';
        document.body.style.position = 'static';
        document.body.scrollTop = Number(scrollTop); // 移动端
        document.documentElement.scrollTop = Number(scrollTop); // PC端
      }
    },
  },
  mounted() {
    //
  },
  methods: {
    getScrollOffset() {
      return {
        x: document.body.scrollLeft || document.documentElement.scrollLeft,
        y: document.body.scrollTop || document.documentElement.scrollTop,
      };
    },
    handlerClose() {
      if (!this.confirm) {
        this.$emit('change', false);
      }
    },
    handlerCancel() {
      this.$emit('cancel');
      this.$emit('change', false);
    },
    handlerConfirm() {
      this.$emit('confirm');
      if (this.confirmClose) {
        this.$emit('change', false);
      }
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
