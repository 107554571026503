<template>
  <div
    :class="`size-${size}`"
    class="ui-avatar"
  >
    <div
      v-if="imgLoadError || !compSrc"
      class="ui-avatar__error"
    />
    <div
      v-else-if="imgLoading"
      class="ui-avatar__loading"
    />
    <div
      :class="{'ui-avatar__hidden': imgLoadError || imgLoading}"
      class="ui-avatar__img-wrapper"
    >
      <img
        v-if="compSrc"
        :src="compSrc"
        @load="onImgLoaded"
        @error="onImgError"
        class="ui-avatar__img"
        loading="lazy"
      >
      <span
        v-if="iconDecoType"
        class="deco"
      >
        <span
          :class="iconDecoType"
          class="icon-deco"
        />
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UiAvatar',
  props: {
    avatar: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'm',
      validator: val => (['xs', 's', 'm', 'l', 'xl', 'xxl'].indexOf(val) > -1),
    },
    accountType: {
      type: Number,
      default: 0,
    },
    vipType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      imgLoadError: false,
      imgLoading: true,
    };
  },
  computed: {
    iconDecoType() {
      if (Number(this.vipType) === 1) {
        return {
          1: 'icon-v-blue', // 官方一级账号
          2: 'icon-v-blue', // 官方二级账号
          3: 'icon-star-blue', // 官方个人账号
          4: 'icon-v-orange', // 媒体企业
          5: 'icon-star-orange', // 媒体个人
        }[Number(this.accountType)] || '';
      }
      return '';
    },
    compSrc() {
      return this.avatar ? decodeURIComponent(this.avatar) : '';
    },
  },
  beforeDestroy() {
    this.imgLoadError = false;
    this.imgLoading = true;
  },
  methods: {
    onImgError() {
      this.imgLoadError = true;
    },
    onImgLoaded() {
      this.imgLoadError = false;
      this.imgLoading = false;
    },
  },
};
</script>
<style lang="scss" src="./scss/index.scss"></style>
