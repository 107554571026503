<template>
  <div class="com-post-loading">
    加载中，莫慌莫慌~
  </div>
</template>

<script>
import { logger } from '@/util/logger';

export default {
  data() {
    return {
    };
  },
  beforeCreate() {
    if (window.skeleton) {
      logger.info('post-loading open skeleton');
      window.skeleton.load(window.location.pathname);
    }
  },
  destroyed() {
    this.$closeSkeleton();
  },
};
</script>
<style lang="scss" src="./scss/index.scss"></style>
