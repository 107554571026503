<template>
  <div
    class="ui-scroll-view"
  >
    <div
      class="ui-scroll-view-inner"
    >
      <slot />
      <div
        v-if="noMore"
      >
        <slot name="loading">
          <div
            :style="noMoreFooterStyle"
            class="scroll-view-footer"
          >
            {{ noMoreText }}
          </div>
        </slot>
      </div>
      <div
        v-else-if="error"
      >
        <slot name="error">
          <div
            @click="onBottomErrorClickHandler"
            class="scroll-view-footer"
          >
            {{ '加载失败，点击重试' }}
          </div>
        </slot>
      </div>
      <div
        v-else
      >
        <slot name="loading">
          <div class="scroll-view-footer">
            {{ '正在加载中' }}
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { throttle } from '@/util/util';

export default {
  name: 'ScrollView',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    noMore: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    bottomDistance: {
      type: Number,
      default: 0,
    },
    noMoreText: {
      type: String,
      default: '没有更多内容了',
    },
    noMoreFooterStyle: {
      type: Object,
      default: null,
    },
  },
  watch: {
    loading(val) {
      if (!val) {
        // 当停止加载的时候，如果高度不够，就认为到底端了。
        // 注意: 需要等到dom更新之后再去做检测
        // this.$nextTick(() => {
        //   this.handlerScroll();
        // });
      }
    },
  },
  mounted() {
    this.scrollEventTarget = this.getScrollEventTarget(this.$el);
    this.scrollHandler = throttle(() => {
      this.$emit('scroll');
      if (this.loading || this.noMore) return false;
      if (this.checkBottom()) {
        // console.log('reachBottom');
        this.$emit('reachBottom');
      }
      return true;
    }, 50);
    this.scrollEventTarget.addEventListener('scroll', this.scrollHandler);
  },
  beforeDestroy() {
    if (this.scrollEventTarget) {
      this.scrollEventTarget.removeEventListener('scroll', this.scrollHandler);
    }
  },
  methods: {
    getScrollEventTarget(element) {
      let currentNode = element;
      // bugfix, see http://w3help.org/zh-cn/causes/SD9013 and http://stackoverflow.com/questions/17016740/onscroll-function-is-not-working-for-chrome
      while (currentNode && currentNode.tagName !== 'HTML' && currentNode.tagName !== 'BODY' && currentNode.nodeType === 1) {
        const { overflowY } = getComputedStyle(currentNode);
        if (overflowY === 'scroll' || overflowY === 'auto') {
          // console.log(currentNode);
          // console.log(overflowY);
          return currentNode;
        }
        currentNode = currentNode.parentNode;
      }
      return window;
    },
    checkBottom() {
      if (this.scrollEventTarget === window) {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        // const { clientHeight } = document.documentElement;
        // 改成用下面的方法，是因为手机浏览器在上划的时候地址栏会收起，clientHeight却没有相应变大，从而计算失败
        const clientHeight = window.innerHeight;
        const { scrollHeight } = document.body;
        // console.log('el:', 'window');
        // console.log(`window scrollTop: ${scrollTop}`);
        // console.log(`window clientHeight: ${clientHeight}`);
        // console.log(`window scrollHeight: ${scrollHeight}`);
        // console.log(`scrollTop + clientHeight +
        //  this.bottomDistance: ${scrollTop + clientHeight + this.bottomDistance}`);

        return Math.ceil(scrollTop + clientHeight + this.bottomDistance) >= scrollHeight;
      }
      const { scrollTop, clientHeight, scrollHeight } = this.scrollEventTarget;
      // console.log('this.$el.getBoundingClientRect().bottom)', this.$el.getBoundingClientRect().bottom);
      // console.log('parseInt(this.scrollEventTarget.getBoundingClientRect().bottom) + 1',
      //  parseInt(this.scrollEventTarget.getBoundingClientRect().bottom, 10) + 1);
      // console.log('this.bottomDistance', this.bottomDistance);
      // console.log('scrollEventTarget', this.scrollEventTarget);
      // console.log('scrollEventTarget scrollTop', this.scrollEventTarget.scrollTop);
      // console.log('scrollEventTarget clientHeight', this.scrollEventTarget.clientHeight);
      // console.log('scrollEventTarget scrollHeight', this.scrollEventTarget.scrollHeight);
      return Math.ceil(scrollTop + clientHeight + this.bottomDistance) >= scrollHeight;
    },
    onBottomErrorClickHandler() {
      this.$emit('bottomErrorClick');
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
