<template>
  <div class="com-share-download">
    <div class="com-share-download-inner">
      <div class="com-share-download-inner-logo" />
      <div class="com-share-download-inner-text">
        <h4 class="com-share-download-inner-text-h4 ui-nowrap">
          闪现一下
        </h4>
        <p class="com-share-download-inner-text-p ui-nowrap">
          去闪现逛逛 大量礼包等你来拿
        </p>
      </div>
      <Button
        :type="'primary'"
        :size="'m'"
        @click.native="linkToApp"
        class="com-share-download-inner-btn"
      >
        打开APP
      </Button>
    </div>
  </div>
</template>
<script>
import Button from '@/ui/button/index.vue';
// import launchApp from '@/util/launchApp';
import launchApp from '@/util/launchApp';

export default {
  name: 'ComShareDownload',
  components: {
    Button,
  },
  props: {
    customSchema: {
      type: String,
      default: '',
    },
    channel: {
      type: String,
      default: '',
    },
  },
  inject: ['reportData'],
  data() {
    return {

    };
  },
  methods: {
    linkToApp() {
      if (this.reportData) {
        this.reportData({
          moduleid: 27,
          eventtype: 1,
          extendid: 1,
        });
        // this.report({
        //   pageid: '2001',
        //   moduleid: '00001',
        //   eventtype: '03',
        //   extendid: '01',
        //   inchannel: this.channel,
        // }, 'H5下载底栏点击');
      }
      // eslint-disable-next-line no-new
      launchApp({
        scheme: this.customSchema,
        channel: this.channel || this.$route.query.shanxianinchannel || 'ShanxianOfficial',
      });
    },
  },
};
</script>
<style lang="scss" src="./scss/index.scss"></style>
