<!-- eslint-disable vue/valid-template-root -->
<template>
  <PostLoading v-if="pageLoading" />
  <PostError
    v-else-if="pageError"
    :custom-header="false"
    :text="pageError"
    @buttonClick="reloadPage"
    button-title="刷新一下"
    status="fail"
  />
  <PostError
    v-else-if="!pageError && !postList.length"
    :custom-header="false"
    @buttonClick="reloadPage"
    text="暂无内容"
    button-title="刷新一下"
    status="empty"
  />
  <ScrollView
    v-else
    :loading="loadingStatus"
    :error="pageError"
    :no-more="!hasMore"
    :bottom-distance="0"
    @reachBottom="getPageData"
    class="page-career-detail__list"
  >
    <li
      v-for="item in postList"
      :key="item.id"
      @click="onClickPostItem(item)"
    >
      <div class="page-career-detail__list-content">
        <div class="page-career-detail__list-title-container">
          <h2 class="page-career-detail__list-title">
            {{ item.title }}
          </h2>
          <div
            v-if="!isPostItemHasPhoto(item) && item.hasRedDot"
            class="page-career-detail__list-title-red-dot"
          />
        </div>
        <author-horizontal
          v-if="item.author"
          :avatar="item.author.iconUrl || ''"
          :nickname="item.author.nickName || ''"
          :account-type="item.author.accountType"
          :vip-type="item.author.vipType"
          :read-num="item.readNum"
        />
      </div>
      <div
        v-if="isPostItemHasPhoto(item)"
        class="page-career-detail__list-img-container"
      >
        <img
          :src="item.pictureList.info[0].url"
          class="page-career-detail__list-img"
        >
        <div
          v-if="item.hasRedDot"
          class="page-career-detail__list-img-red-dot"
        />
      </div>
    </li>
  </ScrollView>
</template>

<script>
import PostLoading from '@/component/post-loading/index.vue';
import PostError from '@/component/post-error/index.vue';
import ScrollView from '@/ui/scroll-view/index.vue';
import AuthorHorizontal from '@/component/post-author/author-horizontal.vue';
import groupApi from '@/api/group';
import reportApi from '@/api/report';
import jsbridge, { setWebTitle, receiveGlobalNotification } from '@/jsbridge';
import uniOpenUrl from '@/util/uni-open-url';
import { checkEnv } from '@/util/browser-env';
import { checkRedDotLight, checkIsSupportRedDotQuery } from '@/util/red-dot';

export default {
  name: 'CareerDetail',
  components: {
    PostLoading,
    PostError,
    AuthorHorizontal,
    ScrollView,
  },
  data() {
    return {
      pageLoading: true,
      pageError: false,
      postList: [],
      hasMore: true,
      page: 0,
      size: 10,
      loadingStatus: false,
      env: checkEnv(),
    };
  },
  computed: {
    /** postList的id索引(key: id，value: index) */
    postListIndexMap() {
      const indexMap = {};
      this.postList.forEach((item, index) => {
        indexMap[item.id] = index;
      });

      return indexMap;
    },
  },
  mounted() {
    this.getPageData();
    this.reportData({
      eventtype: '01',
      extendid: '01',
    }, '列表页面pv');
    setWebTitle(decodeURIComponent(this.$route.query.title || ' ') || ' ');
    // 监听红点信息
    if (this.env.isApp) {
      receiveGlobalNotification('g_red_dot_change', () => {
        this.handleRedDotUpdate();
      });
    }
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
    getPageData() {
      if (!this.hasMore) {
        return;
      }
      this.loadingStatus = true;
      groupApi.getGroupToolCareerDetail({
        id: this.$route.query.id,
        page: this.page + 1,
        size: this.size,
      }).then((result) => {
        this.pageLoading = false;
        this.loadingStatus = false;
        if (result.ret === 0) {
          this.postList = [...this.postList, ...result.postList];
          this.hasMore = result.pagination.totalPage > this.page + 1;
          this.page = result.pagination.page;
          this.size = result.pagination.pageSize;
        } else {
          this.pageError = result.msg || '服务器繁忙，请稍后重试';
        }
        // 监听红点信息
        this.handleRedDotUpdate();
      })
        .catch(() => {
          this.pageLoading = false;
          this.pageError = '服务器繁忙，请稍后重试';
        })
        .finally(() => {
          this.loadingStatus = false;
        });
    },
    onClickPostItem(item) {
      this.onClickRedDotTree(item);
      this.jumpToDetail(item);
    },
    jumpToDetail(item) {
      this.reportData({
        eventtype: '02',
        extendid: '01',
        contentid: item.id,
        contentname: item.title,
      }, '列表页面feeds点击');
      uniOpenUrl(item.schemeUrl);
    },
    // 数据上报
    reportData(params, desc) {
      return reportApi({
        pageid: this.env.isApp ? '1308' : '2011',
        moduleid: '00059',
        ext1: this.$route.query.pid,
        ext3: this.$route.query.id,
        ext6: this.$route.query.group_id,
        ...params,
      }, desc);
    },
    handleRedDotUpdate() {
      if (!checkIsSupportRedDotQuery()) {
        return;
      }

      const {
        group_id: groupId, tool_bar_item_id: iconId, group_first_id: groupFirstId, id: groupSecondId,
      } = this.$route.query;
      const nodeId = `ID_GROUP_SECOND@${groupId}@${iconId}@${groupFirstId}@${groupSecondId}`;
      jsbridge.redDot.queryRedDotTree({ nodeId, depth: 2 }).then((res) => {
        const redDotList = res?.children || [];
        for (const redDot of redDotList) {
          const originRedDotId = redDot?.id;
          const redDotIdList = originRedDotId?.split('@') || [];
          const redDotId = redDotIdList?.[Math.max(redDotIdList.length - 1, 0)] || '';
          const postItemIndex = this.postListIndexMap[redDotId];
          const postItem = this.postList[postItemIndex];
          if (postItem) {
            // 记录下红点的id，用于点消
            postItem.redDotId = originRedDotId;
            this.$set(postItem, 'hasRedDot', checkRedDotLight(redDot));
          }
        }
      });
    },
    onClickRedDotTree(postItem) {
      const { hasRedDot = false, redDotId = '' } = postItem || {};
      if (!hasRedDot) {
        return;
      }

      jsbridge.redDot.onRedDotClick({ nodeId: redDotId });
    },
    isPostItemHasPhoto(postItem) {
      return postItem?.pictureList?.info?.length > 0;
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
