/**
 * 统一设置分享操作
 *
 */
/* eslint-disable import/prefer-default-export */
import { getScript } from '@/util/util';

const TCG_SHARE_NAME = 'gamecommunity_h5_share_2020';

/**
 * H5外部页面分享到手Q、微信参数设置
 * http://km.oa.com/group/TGideas/articles/show/243512
 *
 * @param {Object} params
 * @param {String} params.shareTitle 分享标题
 * @param {String} params.shareDesc 分享描述文字
 * @param {String} param.shareImgUrl 分享缩略图
 * @param {String} param.shareLink 分享链接
 */
export function setH5Share(params) {
  // 调用tgmobile分享组件
  function setTGMobileShare(tgmParams, retryTimes = 0) {
    if (window.TGMobileShare) {
      window.TGMobileShare({
        shareTitle: tgmParams.shareTitle,
        shareDesc: tgmParams.shareDesc,
        shareImgUrl: tgmParams.shareImgUrl,
        shareLink: tgmParams.shareLink,
        actName: TCG_SHARE_NAME,
        // 初始化完成的回调方法，tgms参数为Object类型，有isWX，isQQ，fileName，tcssName四个属性
        // eslint-disable-next-line no-unused-vars
        onInit(tgms) {
          // alert(`${'分享信息设置完毕啦！当前是否是微信访问：\n'}${tgms.isWX}\n当前是否是手Q访问：\n${tgms.isQQ}\n当前文件名(除去扩展名)为：
          // \n${tgms.fileName}\n当前tcss点击流命名前缀为：\n${tgms.tcssName}\n当前手机系统为：\n${tgms.osType}`);
        },
        // onShare: {
        //   WXToMessageSuccess () { alert('【成功】分享给微信好友！'); },
        //   WXToMessageCancel () { alert('【取消】分享给微信好友！'); },
        //   WXToTimelineSuccess () { alert('【成功】分享给微信朋友圈！'); },
        //   WXToTimelineCancel () { alert('【取消】分享给微信朋友圈！'); },
        //   QQToQQSuccess () { alert('【成功】分享给QQ好友！'); },
        //   QQToQQCancel () { alert('【取消】分享给QQ好友！'); },
        //   QQToQzoneSuccess () { alert('【成功】分享给QQ空间！'); },
        //   QQToQzoneCancel () { alert('【取消】分享给QQ空间！'); },
        //   QQToMessageSuccess () { alert('【成功】分享给微信好友！'); },
        //   QQToMessageCancel () { alert('【取消】分享给微信好友！'); },
        //   QQToTimelineSuccess () { alert('【成功】分享给微信朋友圈！'); },
        //   QQToTimelineCancel () { alert('【取消】分享给微信朋友圈！'); },
        // },
      });
    } else if (retryTimes < 1) {
      getScript('https://ossweb-img.qq.com/images/js/TGMobileShare/TGMobileShare.min.js').then(() => {
        setTGMobileShare(params, 1);
      });
    }
  }
  setTGMobileShare(params);
}
