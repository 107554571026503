import compareVersion from '@/util/compareVersion';
import { checkEnv } from '@/util/browser-env';
import { isServerRender } from '@/util/env';

const ENV = checkEnv();

/**
 * 检查红点数据是否可以点亮
 * @param { object } redDot 红点数据
 * {
    "id": "XXX",
        "style": -1, // 样式，1:数字，2：小红点，3：异型红点
        "num": 0, // 红点数字
        "version": 0, // long, 红点节点的版本号
        "clickVersion": 0, // long, 红点节点最后点击的版本号
        "startTime": 0, // long, 红点生效起始UTC时间
        "endTime": 0, // long, 红点失效UTC时间
        "children": [ // 子节点列表
            {}  // 类型为自身的递归
        ]
    }
 * @returns { boolean } 是否能够点亮
 * @example
 * checkRedDotLight(redDot) => true
 */
function checkRedDotLight(redDot) {
  if (!redDot) {
    return false;
  }

  const isVersionRight = Number(redDot.clickVersion) < Number(redDot.version);
  return redDot.style > 0 && isVersionRight && redDot.num > 0;
}

/**
 * 检查当前H5版本是否支持红点查询
 * @returns { boolean } 是否支持红点信息查询
 * @example
 * checkIsSupportRedDotQuery() => true
 */
function checkIsSupportRedDotQuery() {
  if (isServerRender) {
    return false;
  }

  /** ios的版本，在1.8.4或以上的版本才正式支持H5红点查询，1.8.2 ~ 1.8.3虽然支持，但是有bug，因此不使用 */
  const isIOSNotSupport = ENV.ios && compareVersion('1.8.4') < 0;
  /** 安卓版本，在1.8.2或以上才支持H5红点查询 */
  const isAndroidNotSupport = ENV.android && compareVersion('1.8.2') < 0;
  if (!ENV.isApp || isIOSNotSupport || isAndroidNotSupport) {
    return false;
  }
  return true;
}

export { checkRedDotLight, checkIsSupportRedDotQuery };
