<template>
  <div class="p-welfare">
    <!-- 顶部 -->
    <div
      v-if="isApp"
      class="p-welfare-header"
    >
      <div
        @click="onBackClick()"
        class="icon-back"
      />
      <div class="p-welfare-header-text">
        {{ groupName }}福利站
      </div>
      <SubscribeButton
        v-if="groupName"
        :has-subsribed="hasSubsribed"
        @subscribeButtonClick="onSubscribeButtonClick"
      />
      <div
        @click="onShareClick(1)"
        class="icon-share"
      />
    </div>
    <!-- 整体福利内容 -->
    <div
      :class="{'is-app': isApp,}"
      class="p-welfare-main"
    >
      <div class="p-welfare-main-photo">
        <div class="p-welfare-main-photo-inner">
          <div
            ref="mainPhotoInnerTextRef"
            class="p-welfare-main-photo-inner-text"
          >
            <p
              v-for="(item, index) in groupNameArr"
              :key="index"
              class="p-welfare-main-photo-inner-text-name"
            >
              {{ item }}
            </p>
          </div>
        </div>
      </div>
      <div class="p-welfare-main-radius" />
      <div class="p-welfare-main-welfare">
        <!--分享提示条 请求成功才显示-->
        <div
          v-if="isApp"
          :class="['', 'loading', 'fail'].indexOf(pageStatus) > -1 ? '' : isFirstShare ? 'is-first' : 'not-first'"
          @click="onShareClick(2)"
          class="p-welfare-main-welfare-share"
        />
        <div
          v-if="pageStatus === 'loading'"
          class="p-welfare-main-welfare-loading"
        />
        <!--空状态和失败状态-->
        <div
          v-else-if="['fail', 'empty'].indexOf(pageStatus) > -1"
          class="p-welfare-main-welfare-empty"
        >
          <div class="p-welfare-main-welfare-empty-img" />
          <span class="p-welfare-main-welfare-empty-text">稍等一下 马上就来</span>
        </div>
        <template v-else-if="pageStatus === 'success'">
          <scroll-view
            :loading="isLoading"
            :no-more="isEnd"
            :error="isError"
            :bottom-distance="400"
            @reachBottom="onReachBottom"
            class="p-welfare-main-welfare-scroll"
          >
            <div class="p-welfare-main-welfare-list">
              <!-- 福利活动卡片详情内容 -->
              <div
                v-for="(item,index) in welfareList"
                :key="index"
                class="item"
              >
                <div class="item-card">
                  <div class="item-card-header">
                    <div class="item-card-header-name">
                      SHAN CARD
                    </div>
                    <div class="item-card-header-style">
                      <div class="item-card-header-style-semicircle" />
                    </div>
                    <div class="item-card-header-deco" />
                  </div>
                  <!-- 福利活动标签项 -->
                  <div
                    v-if="Array.isArray(item.labelListComputed) && item.labelListComputed.length > 0"
                    class="item-card-label"
                  >
                    <div class="item-card-label-tag">
                      <div class="item-card-label-tag-inner">
                        <span
                          v-for="(labelItem, labelIndex) in item.labelListComputed"
                          :key="labelIndex"
                          class="item-card-label-tag-inner-new"
                        >{{ labelItem }}</span>
                        <span class="item-card-label-tag-inner-blank" />
                      </div>
                    </div>
                    <div class="item-card-label-triangle" />
                  </div>
                  <!-- 福利详情 -->
                  <div class="item-card-detail">
                    <div
                      :style="{backgroundImage: `url(${item.picUrl})`}"
                      class="item-card-detail-picture"
                    />
                    <div class="item-card-detail-characters">
                      <div class="item-card-detail-characters-title ui-nowrap">
                        {{ item.title }}
                      </div>
                      <div
                        v-if="Number(item.startTs) && Number(item.endTs)"
                        class="item-card-detail-characters-time ui-nowrap"
                      >
                        活动时间：{{ item.startTs | formatDate('M-D') }} 至 {{ item.endTs | formatDate('M-D') }}
                      </div>
                      <div
                        v-else
                        class="item-card-detail-characters-time ui-nowrap"
                      >
                        活动时间：长期
                      </div>

                      <div class="item-card-detail-characters-platform">
                        <p class="item-card-detail-characters-platform-main ui-nowrap">
                          活动平台：{{ item.platName }}
                        </p>
                        <template v-if="item.kankanUrl">
                          <p
                            @click="onItemLinkClick(item)"
                            class="item-card-detail-characters-platform-url"
                          >
                            去看看
                          </p>
                          <div class="item-card-detail-characters-platform-icon" />
                        </template>
                      </div>
                    </div>
                  </div>
                  <!-- 福利描述 -->
                  <div class="item-card-describe">
                    <p
                      v-if="item.descr.length < 60"
                      class="item-card-describe-text"
                    >
                      {{ item.descr }}
                    </p>
                    <p
                      v-else
                      :class="item.unfold ? 'unfold' : 'fold'"
                      class="item-card-describe-text"
                    >
                      {{ item.unfold ? item.descr : item.descr.slice(0, 60) }} <span
                        v-if="item.descr.length > 60"
                        @click="onDescribeSpreadClick(item)"
                        class="item-card-describe-text-spread"
                      >{{ item.unfold ? '收起' : '展开' }}</span>
                    </p>
                  </div>
                  <!-- 福利奖品 -->
                  <div
                    v-if="Array.isArray(item.rewardPicList) && item.rewardPicList.length > 0"
                    class="item-card-prize"
                  >
                    <div class="item-card-prize-content">
                      <div class="item-card-prize-content-inner">
                        <div
                          v-for="(rewardItem, itemIndex) in item.rewardPicList"
                          :key="itemIndex"
                          class="item-card-prize-content-inner-item"
                        >
                          <div
                            :style="{backgroundImage: `url(${rewardItem.url})`}"
                            class="item-card-prize-content-inner-item-img"
                          />
                          <span class="item-card-prize-content-inner-item-text">{{ rewardItem.descr }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="((welfareList.length === 1 && index === 0) || (welfareList.length > 1 && index === 1)) && isApp"
                  class="item-mall"
                >
                  <div class="item-mall-icon" />
                  <div class="item-mall-text">
                    积分商城还有礼包可以兑换哦
                  </div>

                  <div
                    @click="goToPointsMall"
                    class="item-mall-btn"
                  >
                    <span class="item-mall-btn-text">去兑换</span>
                  </div>
                </div>
              </div>
            </div>
            <div slot="loading" />
            <div slot="no-more" />
            <div
              slot="error"
              class="p-welfare-main-welfare-error"
            >
              加载失败，请点击重试
            </div>
            <div
              v-if="isApp"
              class="p-welfare-main-welfare-footer"
            >
              <div class="p-welfare-main-welfare-footer-line" />
              <span>闪现一下·福利站</span>
              <div class="p-welfare-main-welfare-footer-line" />
            </div>
            <div
              v-else
              class="p-welfare-main-welfare-gift"
            >
              <div class="p-welfare-main-welfare-gift-bg">
                <p class="p-welfare-main-welfare-gift-inner">
                  还有 <strong>{{ giftPkgNumber || '?' }}</strong> 个礼包等你认领哦！
                </p>
              </div>
            </div>
          </scroll-view>
        </template>
      </div>
    </div>
    <template v-if="!isApp">
      <div class="p-welfare-fake" />
      <div class="p-welfare-footer">
        <div class="p-welfare-footer-inner">
          <Avatar
            :avatar="launchObj.iconUrl"
            class="p-welfare-footer-inner-avatar"
            size="m"
          />
          <div class="p-welfare-footer-inner-text">
            <div
              :class="{'user': launchObj.userFlag}"
              class="p-welfare-footer-inner-text-p1 ui-nowrap"
            >
              {{ launchObj.nickName }}
            </div>
            <div
              :class="{'user': launchObj.userFlag}"
              class="p-welfare-footer-inner-text-p2 ui-nowrap"
            >
              {{ launchObj.desc }}
            </div>
          </div>
          <Button
            :type="'primary'"
            :width="60"
            @click="onLaunchApp"
            class="p-welfare-footer-inner-btn"
            size="s"
          >
            打开
          </Button>
        </div>
      </div>
    </template>
    <Dialog
      v-if="isApp"
      v-model="showUnsubscribeDialog"
      @cancel="onUnsubscribeConfirm"
      confirm
      confirm-text="我再想想"
      cancel-text="退订"
    >
      取消订阅后将无法第一时间收到福利通知哦
    </Dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import groupApi from '@/api/group';
import reportApi from '@/api/report';
import {
  getUserInfo, popBack, showShareDialog, showTaskToast,
} from '@/jsbridge';
import {
  getWelfareInfo,
  sharePageAddExp,
  getGameGiftPkgNum,
} from '@/api/welfare';
import uniOpenUrl, { toStaticUrl } from '@/util/uni-open-url';
import ScrollView from '@/ui/scroll-view/index.vue';
import { setH5Share } from '@/util/h5-share';
import Button from '@/ui/button';
import Avatar from '@/ui/avatar/index.vue';
import Dialog from '@/ui/dialog/index.vue';
import launchApp from '@/util/launchApp';
import { appendParams } from '@/util/uri';
import { setTitle } from '@/util/util';
import SubscribeButton from './components/subsribe-button/index.vue';

export default {
  components: {
    Avatar, Button, ScrollView, SubscribeButton, Dialog,
  },
  filters: {
    formatDate(time, str) {
      if (Number(time) === 0) return '长期';
      const oDate = new Date(Number(time) * 1000);
      const M = oDate.getMonth() + 1;
      const D = oDate.getDate();
      return str.replace(/(M|D)/g, t => ({ M, D }[t]));
    },
  },
  data() {
    return {
      appIconUrl: 'https://static.gameplus.qq.com/business/Share/iOSAPPicon100.png',
      title: '【腾讯官方】再也不怕错过腾讯游戏的福利了！',
      summary: '我在闪现一下发现了一个超全的游戏礼包汇总，速来！',
      // 圈子id
      gid: 0,
      // 页面id
      pid: 0,
      // 来源 帖子1 圈子工具栏2
      sid: 0,
      // 圈子名
      groupName: '',
      // 圈子名%5
      groupNameArr: [],
      // 圈子图标
      groupIconUrl: '',
      // 页面状态
      pageStatus: '',
      // 第几页
      pageIndex: 1,
      // 每一页多少条
      pageSize: 5,
      // 是否第一次分享
      isFirstShare: false,
      // 是否在加载
      isLoading: false,
      // 是否全部加载完成
      isEnd: false,
      // 是否加载出错
      isError: false,
      // 活动列表
      welfareList: [],
      // 用户信息
      userInfo: null,
      // 启动信息
      launchObj: {},
      // 全部游戏礼包数量
      giftPkgNumber: 0,
      // 是否已订阅
      hasSubsribed: false,
      showUnsubscribeDialog: false,
    };
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp,
    }),
  },
  created() {
    this.gid = Number(this.$route.query.gid);
    this.pid = Number(this.$route.query.pid);
    this.sid = Number(this.$route.query.sid);
    const {
      gid, pid, nickName, iconUrl,
    } = this.$route.query;
    this.gid = gid;
    this.pid = pid;
    if (nickName && iconUrl) {
      this.launchObj = {
        nickName: `好友${nickName}为你推荐`,
        iconUrl,
        desc: '腾讯游戏官方社区超全福利站',
        userFlag: true,
      };
    } else {
      this.launchObj = {
        nickName: '闪现一下',
        iconUrl: this.appIconUrl,
        desc: '游戏玩家兴趣社区',
        userFlag: false,
      };
    }
    if (this.gid > 0) {
      this.pageStatus = 'loading';
      this.initPageData();
    } else {
      this.pageStatus = 'fail';
    }
  },
  methods: {
    initPageData() {
      this.pageIndex = 1;
      this.getWelfareInfo();
      if (this.isApp) {
        this.$store.dispatch('user/getUserInfo');
        getUserInfo()
          .then((res) => {
            // console.log(res);
            if (Number(res.uid) > 0) {
              this.userInfo = res;
            } else {
              this.userInfo = {};
            }
          });
      } else {
        getGameGiftPkgNum()
          .then((res) => {
            this.giftPkgNumber = res.giftPkgNum;
          });
      }
    },
    onReachBottom() {
      this.pageIndex += 1;
      this.getWelfareInfo();
    },
    getWelfareInfo() {
      if (this.isLoading) return;
      this.isLoading = true;
      getWelfareInfo({
        gid: this.gid,
        pid: this.pid,
        page: this.pageIndex,
        pageSize: this.pageSize,
      })
        .then((res) => {
          if (res.ret === 0) {
            // debugger;
            let tempWelfareList = [];
            if (Array.isArray(res.weldList)) {
              tempWelfareList = res.welfList.map((item) => {
                const labelListArr = item.labelList.split(';').filter(t => t !== '');
                return {
                  ...item,
                  labelListComputed: labelListArr,
                };
              });
            }
            if (this.pageIndex === 1) {
              if (tempWelfareList.length === 0) {
                this.pageStatus = 'empty';
              } else {
                this.pageStatus = 'success';
              }

              // res.groupName = '王者';
              // res.groupName = '王者荣耀王者';
              // res.groupName = '王者荣耀王者荣耀';
              // res.groupName = '王者荣耀王者荣耀王者';
              // res.groupName = '王者荣耀王者荣耀王者王者荣耀';
              this.groupName = res.groupName;
              setTitle(`${this.groupName}福利站`);
              this.groupNameArr = (() => {
                const len = res.groupName.length;
                let i = 0;
                const tempArr = [];
                for (i = 0; i < len; i += 6) {
                  if (i < 12) {
                    tempArr.push(res.groupName.slice(i, i + 6));
                  } else {
                    tempArr[1] += '...';
                    break;
                  }
                }
                return tempArr;
              })();
              this.groupIconUrl = res.groupIconUrl;
              if (this.isApp) {
                this.welfareList = tempWelfareList;
              } else {
                this.welfareList = tempWelfareList.slice(0, 3);
              }
              this.isFirstShare = res.haveTodayShared === 0;
              this.title = `【腾讯官方】再也不怕错过${this.groupName || '闪现社区'}的福利了！`;
              // this.makeGroupNameAnimate();
              this.setShareInfo();
              if (this.isApp) {
                reportApi({
                  operid: '1316000010101',
                  ext1: this.sid,
                  ext6: this.gid,
                  prev_id: this.$route.query.prev_id,
                }, '福利站主页pv');
              } else {
                reportApi({
                  operid: '2012000010101',
                  ext6: this.gid,
                  prev_id: this.$route.query.prev_id,
                }, '福利站主页pv');
              }
            } else {
              const idArr = this.welfareList.map(item => item.id);
              this.welfareList = this.welfareList.concat(tempWelfareList.filter(item => idArr.indexOf(item.id) === -1));
            }
            if (this.isApp && res.pagination) {
              this.isEnd = this.pageIndex >= res.pagination.totalPage;
            } else {
              this.isEnd = true;
            }
            this.hasSubsribed = res.isSub === 1;
          } else {
            throw res.ret;
          }
        })
        .catch((err) => {
          this.$logger.err('getWelfareInfo', err);
          if (this.pageIndex === 1) {
            this.pageStatus = 'fail';
          } else {
            this.pageIndex -= 1;
            this.isError = true;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    setShareInfo() {
      if (!this.isApp) {
        setH5Share({
          shareTitle: this.title,
          shareDesc: this.summary,
          shareLink: appendParams(window.location.href, {
            nickName: this.userInfo?.nickName || '',
            iconUrl: this.userInfo?.iconUrl || '',
            sid: 3,
          }),
          shareImgUrl: this.groupIconUrl || this.appIconUrl,
        });
      }
    },
    onShareClick(source) {
      if (!this.isApp || this.pageStatus === '') return;
      reportApi({
        operid: '1316000590301',
        ext1: source,
        ext6: this.gid,
      }, '福利站分享按钮点击');
      showShareDialog({
        title: this.title,
        summary: this.summary,
        targetUrl: appendParams(window.location.href, {
          nickName: this.userInfo?.nickName || '',
          iconUrl: this.userInfo?.iconUrl || '',
          sid: 3,
        }),
        thumbUrl: this.groupIconUrl || this.appIconUrl,
      })
        .then((res2) => {
          // console.log(`showShareDialog: ${JSON.stringify(res2)}`);
          if (res2.result === 0) {
            this.reportShare({
              type: res2?.data?.type || '',
              source,
            });
            if (!this.isFirstShare) return;
            if (this.userInfo) {
              this.sharePageAddExp();
            } else {
              getUserInfo()
                .then((res) => {
                  if (Number(res.uid) > 0) {
                    this.sharePageAddExp();
                  }
                });
            }
          }
        });
    },
    reportShare({ type, source } = {}) {
      let ext2 = 0;
      switch (type) {
        case 'WXSession':
          ext2 = 1;
          break;
        case 'WXTimeline':
          ext2 = 2;
          break;
        case 'QQFriend':
          ext2 = 3;
          break;
        case 'QQZone':
          ext2 = 4;
          break;
        default:
          break;
      }
      if (ext2 > 0) {
        reportApi({
          operid: '1316000590302',
          ext1: source,
          ext2,
          ext6: this.gid,
        }, '福利站分享按钮点击');
      }
    },
    sharePageAddExp() {
      if (this.sharePageAddExpStatus === 'loading') return;
      this.sharePageAddExpStatus = 'loading';
      sharePageAddExp({
        pid: this.pid,
      })
        .then((res) => {
          if (res.ret === 0) {
            this.isFirstShare = false;
            showTaskToast({
              title: '分享成功',
              experience: 10,
            });
          }
        })
        .finally(() => {
          this.sharePageAddExpStatus = '';
        });
    },
    // makeGroupNameAnimate () {
    //   this.$nextTick(() => {
    //     const a = this.$refs.mainPhotoInnerTextRef.getBoundingClientRect().width;
    //     const b = this.$refs.mainPhotoInnerTextNameRef.getBoundingClientRect().width;
    //     if (b > a) {
    //       const diff = Math.ceil(b - a);
    //       const s = 0.5;
    //       let d = 'r';
    //       let now = 0;
    //       const r = () => {
    //         requestAnimationFrame(() => {
    //           if (d === 'r') {
    //             now -= s;
    //             if (now === (-1 * diff - 10)) {
    //               d = 'l';
    //             }
    //           } else {
    //             now += s;
    //             if (now === 10) {
    //               d = 'r';
    //             }
    //           }
    //           this.$refs.mainPhotoInnerTextNameRef.style.marginLeft = `${now}px`;
    //           r();
    //         });
    //       };
    //       r();
    //     }
    //   });
    // },
    onItemLinkClick({ kankanUrl: url } = {}) {
      reportApi({
        operid: '1316000010301',
        ext1: 1,
        ext6: this.gid,
      }, '福利站主页页面按钮点击');
      if (url) {
        uniOpenUrl(url);
      }
    },
    onBackClick() {
      popBack();
    },
    goToPointsMall() {
      reportApi({
        operid: '1316000010301',
        ext1: 2,
        ext6: this.gid,
      }, '福利站主页页面按钮点击');
      uniOpenUrl('tgc://native?moduleName=pointsmall');
    },
    onDescribeSpreadClick(item) {
      this.$set(item, 'unfold', !item.unfold);
    },
    onLaunchApp() {
      launchApp({ schema: toStaticUrl().concat('&prev_id=', '1316'), channel: 'sxhdfulizhan' });
    },
    onSubscribeButtonClick() {
      if (this.hasSubsribed) {
        this.showUnsubscribeDialog = true;
      } else {
        this.handleSubscribe('subs');
      }
    },
    handleSubscribe(action) {
      groupApi.subsUnsubsPage({
        pid: this.$route.query.pid,
        action,
        operid: '1',
      }).then((res) => {
        if (res.ret !== 0) {
          throw res.ret;
        }
        if (action === 'subs') {
          this.hasSubsribed = true;
          this.$toast({
            message: '订阅成功～有新福利时会通过系统消息提醒您哦',
            duration: 1000,
          });
          reportApi({
            operid: '2701008000606',
            ext6: this.gid,
          }, '福利站订阅成功');
        } else {
          this.hasSubsribed = false;
          this.$toast({
            message: '已取消订阅～',
            duration: 1000,
          });
          reportApi({
            operid: '2701008000607',
            ext6: this.gid,
          }, '福利站取消订阅成功');
        }
      })
        .catch((err) => {
          this.$logger.err('subsUnsubsPage', err);
          this.$toast({
            message: '操作失败，请重试～',
            duration: 1000,
          });
        });
    },
    onUnsubscribeConfirm() {
      this.handleSubscribe('unsubs');
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>

