<template>
  <DefaultLayout title="闪现一下">
    <NavHeader :menu-status="true">
      <div class="page-career-header-text">
        {{ title }}
      </div>
      <template slot="right-btn">
        <Button
          v-if="substatus===1"
          :width="53"
          @click="$checkAuthDeco(onSubscribeClick)"
          size="s"
          type="primary"
          icon="plus"
          style="padding: 7px"
        >
          订阅
        </Button>
        <Button
          v-else-if="substatus===2"
          :width="53"
          @click="$checkAuthDeco(onUnsubscribeClick)"
          size="s"
          style="padding: 7px"
        >
          已订阅
        </Button>
        <Icon
          @click="$checkAuthDeco(setShareData);setShareDataReport()"
          name="share-black"
          size="xl"
          style="margin-left:14px;"
        />
      </template>
    </NavHeader>
    <PostLoading v-if="pageLoading" />
    <PostError
      v-else-if="pageError"
      :custom-header="false"
      :text="pageError"
      @buttonClick="reloadPage"
      button-title="刷新一下"
      status="fail"
    />
    <PostError
      v-else-if="!pageError && !careerList.length"
      :custom-header="false"
      @buttonClick="reloadPage"
      text="暂无内容"
      button-title="刷新一下"
      status="empty"
    />
    <div
      v-else
      class="page-career-list"
    >
      <div class="page-career-share-guide">
        <img
          v-if="shareBannerStatus === 1"
          src="~./img/share_guide_bar@3x.png"
          width="100%"
          height="auto"
        >
        <img
          v-else-if="shareBannerStatus === 2"
          src="~./img/share_guide_bar2.png"
          width="100%"
          height="auto"
        >
      </div>
      <div class="page-career-list-box">
        <div
          v-if="bodyBackground"
          :class="bodyBackgroundTypeClass[bodyBackgroundType]"
          :style="{backgroundImage: `url(${bodyBackground})`}"
          class="page-career-list-box__animate"
        />
        <ul class="page-career-list__first">
          <template v-for="item in careerList">
            <li
              v-if="item.showType===1"
              :key="item.id"
            >
              <div class="page-career-list__first-name">
                <div
                  v-if="item.icon"
                  class="page-career-list__title-img"
                >
                  <img
                    :src="item.icon"
                    :alt="item.categoryName"
                  >
                </div>
                {{ item.categoryName }}
              </div>
              <ul class="page-carrer-list__post-list">
                <li
                  v-for="post in item.postList"
                  :key="post.id"
                  @click="onPostItemClick(post)"
                  class="page-carrer-list__post-item"
                >
                  <p class="page-carrer-list__post-title">
                    {{ post.title }}
                  </p>
                  <div
                    v-if="post.hasRedDot"
                    class="page-carrer-list__red-dot"
                  />
                </li>
              </ul>
            </li>
            <li
              v-else
              :key="item.id"
            >
              <div class="page-career-list__first-name">
                <div
                  v-if="item.icon"
                  class="page-career-list__title-img"
                >
                  <img
                    :src="item.icon"
                    :alt="item.categoryName"
                  >
                </div>
                {{ item.categoryName }}
              </div>
              <ul
                :class="Number(item.pattern) === 2 ? 'page-career-list__text-list' : ''"
                class="page-career-list__second"
              >
                <li
                  v-for="detail in item.l2List"
                  :key="detail.id"
                  @click="onDetailItemClick(detail, item)"
                >
                  <span v-if="Number(item.pattern) === 2">{{ detail.content }}</span>
                  <img
                    v-else
                    :src="detail.content"
                  >
                  <img
                    v-if="detail.showNewFlag"
                    class="page-career-list__second__new-flag"
                    src="./img/icon-new-flag.png"
                  >
                  <span
                    v-else-if="detail.hasRedDot"
                    class="page-career-list__second__red-dot"
                  />
                </li>
              </ul>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <ShareBar
      v-if="!env.isApp && shareBarData"
      :avatar="shareBarData.avatar"
      :nickname="shareBarData.nickname"
      :channel="'sxnrgonglue'"
    />
    <Dialog
      v-model="unsubscribeDialogShow"
      @cancel="onUnsubscribeConfirm"
      @confirm="onUnsubscribeCancel"
      confirm
      confirm-text="我再想想"
      cancel-text="退订"
      content-title="~"
    >
      取消订阅将不能及时收到攻略库更新哦
    </Dialog>
  </DefaultLayout>
</template>

<script>
import { mapState } from 'vuex';
import { Base64 } from 'js-base64';
import PostLoading from '@/component/post-loading/index.vue';
import PostError from '@/component/post-error/index.vue';
// import PostHeader from '@/component/post-header/index.vue';
import NavHeader from './components/nav-header/index.vue';
import groupApi from '@/api/group';
import reportApi from '@/api/report';
import { checkEnv } from '@/util/browser-env';
import { setH5Share } from '@/util/h5-share';
import jsbridge, {
  setWebTitle, openUrl, openView, showShareDialog,
  showTaskToast, receiveGlobalNotification,
} from '@/jsbridge';
import { logger } from '@/util/logger';
import Button from '@/ui/button';
import Icon from '@/ui/icon';
import uniOpenUrl from '@/util/uni-open-url';
import { checkRedDotLight, checkIsSupportRedDotQuery } from '@/util/red-dot';
import Dialog from '@/ui/dialog/index.vue';
import { isServerRender } from '@/util/env';
import DefaultLayout from '@/layouts/default/index.vue';
import ShareBar from './components/share-bar/index.vue';
import { pageStore, PAGE_STORE_MODULE_NAME } from './store';
import util, { appendParam, getUrlQuery } from '@/util/util';

export default {
  name: 'CareerList',
  components: {
    PostLoading,
    PostError,
    NavHeader,
    Button,
    Icon,
    Dialog,
    DefaultLayout,
    ShareBar,
  },
  data() {
    return {
      // pageLoading: true,
      // pageError: false,
      // careerList: [],
      // groupId: 0,
      pid: 0,
      // bodyBackground: '',
      // bodyBackgroundType: 1,
      bodyBackgroundTypeClass: {
        1: '',
        2: 'rotate',
      },
      // title: '腾讯游戏官方社区',
      summary: '我在闪现发现了一个超全的攻略汇总，速来！',
      env: checkEnv(),
      // substatus: 0,
      // haveTodayShared: true, // 默认不展示分享提示
      unsubscribeDialogShow: false,
      shareBarData: null,
    };
  },

  computed: {
    ...mapState({
      // ['pageLoading', 'pageError', 'pageInfo']
      pageLoading: state => state[PAGE_STORE_MODULE_NAME].pageLoading,
      pageError: state => state[PAGE_STORE_MODULE_NAME].pageError,
      pageInfo: state => state[PAGE_STORE_MODULE_NAME].pageInfo,
    }),
    careerList() {
      return this.pageInfo?.l1List || [];
    },
    /**
      * careerList的id索引(key: id，value: [careerIndex, 'listName', listIndex])
      * listName根据showType的不同，分为了postList和l2List
      */
    careerListIndexMap() {
      const indexMap = {};
      this.careerList.forEach((item, index) => {
        if (item?.showType === 1 && item?.postList) {
          item.postList.forEach((post, postIndex) => {
            indexMap[post.id] = [index, 'postList', postIndex];
          });
        } else if (item?.l2List) {
          item.l2List.forEach((detail, detailIndex) => {
            indexMap[detail.id] = [index, 'l2List', detailIndex];
          });
        }
      });

      return indexMap;
    },
    bodyBackground() {
      return this.pageInfo?.backgroudUrl;
    },
    bodyBackgroundType() {
      return this.pageInfo?.backgroudType;
    },
    groupId() {
      // 1 表示圈子
      if (Number(this.pageInfo?.bandType) === 1) {
        return Number(this.pageInfo?.bandId);
      }
      return 0;
    },
    title() {
      const title = this.pageInfo?.topTitle || '腾讯游戏官方社区';
      if (!isServerRender) {
        setWebTitle(title);
      }
      return title;
    },
    substatus() {
      // 订阅状态：0=未知，先不展示；1=未订阅; 2=已订阅
      if (this.pageInfo && this.env.isApp) {
        if (this.pageInfo.isSubs) {
          return this.pageInfo.haveSubsed ? 2 : 1;
        }
      }
      return 0;
    },
    shareBannerStatus() {
      // 分享引导：0=未知，先不展示；1=未分享; 2=已分享
      if (this.pageInfo && this.env.isApp) {
        if (this.pageInfo.isShareAddexp) {
          return this.pageInfo.haveTodayShared ? 2 : 1;
        }
      }
      return 0;
    },
    comonReportData() {
      return {
        ext6: this.groupId,
        ext1: this.pid,
      };
    },
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    shareContent() {
      const originUrl = window.location.href;
      const query = getUrlQuery(originUrl);
      const urlPath = originUrl.split('?')[0];
      let targetUrl = originUrl;
      if (this.env.isApp && this.userInfo) {
        if (this.userInfo?.uid) {
          try {
            query.shareBarData = Base64.encode(JSON.stringify({
              avatar: this.userInfo.iconUrl,
              nickname: this.userInfo.nickName,
            }));
          } catch (error) {
            logger.error('encode shareBarData error', error);
            delete query.shareBarData;
          }
        } else {
          delete query.shareBarData;
        }
        targetUrl = appendParam(urlPath, query);
      }
      const shareContent = {
        title: this.title,
        summary: this.summary || '我在闪现发现了一个超全的攻略汇总，速来！',
        targetUrl,
        thumbUrl: 'https://static.gameplus.qq.com/business/Share/iOSAPPicon100.png',
      };
      if (this.pageInfo?.ext) {
        shareContent.title = `${this.userInfo.nickName}为你推荐「${this.pageInfo.ext.groupName}」精品攻略`;
        shareContent.thumbUrl = this.pageInfo.ext.groupIconUrl || this.pageInfo.ext.groupIconRul; // TODO 后台拼错
      }
      return shareContent;
    },
  },
  storeName: PAGE_STORE_MODULE_NAME,
  storeModules: {
    [PAGE_STORE_MODULE_NAME]: pageStore,
  },
  asyncData({ store, route }) {
    if (!store.state[PAGE_STORE_MODULE_NAME]) {
      store.registerModule(PAGE_STORE_MODULE_NAME, pageStore);
    }
    return store.dispatch(`${PAGE_STORE_MODULE_NAME}/initPageData`, {
      pid: route.query.pid,
      bandType: route.query.band_type,
      bandId: route.query.band_id,
    });
  },
  beforeCreate() {
    if (!this.$store.state[PAGE_STORE_MODULE_NAME]) {
      this.$store.registerModule(PAGE_STORE_MODULE_NAME, pageStore);
    }
  },
  created() {
    if (this.$route.query.shareBarData) {
      try {
        this.shareBarData = JSON.parse(Base64.decode(this.$route.query.shareBarData));
      } catch (error) {
        logger.error('get shareBarData error', error);
      }
    }
  },
  mounted() {
    this.pid = this.$route.query.pid;
    this.$store.dispatch('user/getUserInfo');
    // 兼容旧版非直出逻辑
    if (this.pageLoading) {
      this.$store.dispatch(`${PAGE_STORE_MODULE_NAME}/initPageData`, {
        pid: this.$route.query.pid,
        bandType: this.$route.query.band_type,
        bandId: this.$route.query.band_id,
      }).then(() => {
        this.reportData({
          eventtype: '01',
          extendid: '01',
          ext6: this.groupId,
          prev_id: this.$route.query.prev_id,
        }, '攻略站页面PV');
        // 设置外部分享
        if (!this.env.isApp) {
          setH5Share({
            shareTitle: this.shareContent.title,
            shareDesc: this.shareContent.summary,
            shareLink: this.shareContent.targetUrl,
            shareImgUrl: this.shareContent.thumbUrl,
          });
        }
        this.handleShowSecondNewModule();
        this.handleRedDotUpdate();
      });
    } else {
      this.$nextTick(() => {
        this.handleShowSecondNewModule();
        this.handleRedDotUpdate();
      });
    }
    if (!isServerRender && this.env.isApp) {
      // 监听红点信息
      receiveGlobalNotification('g_red_dot_change', () => {
        this.handleRedDotUpdate();
      });
    }
    // this.getPageData().then(() => {
    //   this.reportData({
    //     eventtype: '01',
    //     extendid: '01',
    //   }, '攻略站页面PV');
    //   // 设置外部分享
    //   if (!this.env.isApp) {
    //     setH5Share({
    //       shareTitle: this.title || '腾讯游戏官方社区',
    //       shareDesc: this.summary,
    //       shareLink: getShareUrl(),
    //       shareImgUrl: 'https://static.gameplus.qq.com/business/Share/iOSAPPicon100.png',
    //     });
    //   }
    // });
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
    getPageData() {
      if (!this.$route.query.pid) {
        return Promise.reject();
      }
      return groupApi.getGroupToolCareerList({
        pid: this.$route.query.pid,
        bandType: this.$route.query.band_type,
        bandId: this.$route.query.band_id,
      }).then((result) => {
        this.pageLoading = false;
        if (result.ret === 0) {
          setWebTitle(result.pageInfo.topTitle);
          this.title = result.pageInfo.topTitle;
          // 1 表示圈子
          if (Number(result.pageInfo.bandType) === 1) {
            this.groupId = Number(result.pageInfo.bandId);
          }
          this.bodyBackground = result.pageInfo.backgroudUrl;
          this.bodyBackgroundType = result.pageInfo.backgroudType;
          this.careerList = result.pageInfo.l1List;
          if (result.isSubs) {
            this.substatus = result.haveSubsed ? 2 : 1;
          }
          if (result.isShareAddexp) {
            this.haveTodayShared = !!result.haveTodayShared;
          }
        } else {
          this.pageError = result.msg || '服务器繁忙，请稍后重试';
        }
      })
        .catch(() => {
          this.pageLoading = false;
          this.pageError = '服务器繁忙，请稍后重试';
        });
    },
    onDetailItemClick(detail, item) {
      this.onNewSecondModuleClick(detail);
      this.onRedDotClick(detail);
      this.jumpToDetail(detail, item);
    },
    jumpToDetail(detail, item) {
      let url = detail.contentData;
      if (detail.type === 2) {
        const { tool_bar_item_id: toolBarItemId, pid } = this.$route.query;
        url = `${window.location.origin}/group/tool/career-detail?pid=${pid}&id=${detail.id}&group_id=${this.groupId}&tool_bar_item_id=${toolBarItemId}&group_first_id=${item.id}&GROUP_FIRST&title=${encodeURIComponent(Number(item.pattern) === 2 ? detail.content : detail.subTitle)}`;
      }
      this.reportData({
        eventtype: '02',
        extendid: '01',
        ext2: item.id,
        ext3: detail.id,
      }, '攻略二级入口点击位置');
      if (/^tgc:\/\//i.test(url)) {
        openView({
          options: url,
        });
      } else if (!this.env.isApp) {
        window.location.href = url;
      } else {
        openUrl({
          url,
          target: 1,
        });
      }
    },
    setShareData() {
      const shareContent = {
        shareVisible: 1, // 0 分享不可见， 1 可见
        title: this.shareContent.title,
        summary: this.shareContent.summary,
        targetUrl: this.shareContent.targetUrl,
        thumbUrl: this.shareContent.thumbUrl,
      };
      logger.info('showShareDialog', shareContent, this.shareContent);
      showShareDialog(shareContent)
        .then((res2) => {
          if (res2.result === 0) {
            if (!this.haveTodayShared) {
              this.haveTodayShared = true;
              this.sharePageAddExp(); // 增加经验值
            }
            switch (res2.data.type) {
              case 'WXSession':
                this.reportData({
                  moduleid: 59,
                  eventtype: 6,
                  extendid: 1,
                  ext2: 1,
                  ...this.comonReportData,
                }, '分享到微信点击');
                break;
              case 'WXTimeline':
                this.reportData({
                  moduleid: 59,
                  eventtype: 6,
                  extendid: 1,
                  ext2: 2,
                  ...this.comonReportData,
                }, '分享到微信朋友圈点击');
                break;

              case 'QQFriend':
                this.reportData({
                  moduleid: 59,
                  eventtype: 6,
                  extendid: 1,
                  ext2: 3,
                  ...this.comonReportData,
                }, '分享到QQ好友点击');
                break;
              case 'QQZone':
                this.reportData({
                  moduleid: 59,
                  eventtype: 6,
                  extendid: 1,
                  ext2: 4,
                  ...this.comonReportData,
                }, '分享到QQ空间点击');
                break;
              default:
                break;
            }
          }
        });
    },
    setShareDataReport() {
      this.reportData({
        moduleid: 59,
        eventtype: 3,
        extendid: 1,
      }, '攻略战点击右上角分享按钮');
    },
    // 数据上报
    reportData(params, desc) {
      return reportApi({
        pageid: this.env.isApp ? '1308' : '2011',
        moduleid: '00001',
        ext1: this.$route.query.pid,
        ext6: this.groupId,
        ...params,
      }, desc);
    },
    onSubscribeClick() {
      logger.info('onSubscribeClick');
      this.subsUnsubsPage('subs');
      this.reportData({
        moduleid: 80,
        eventtype: 3,
        extendid: 1,
      });
    },
    subsUnsubsPage(action) {
      groupApi.subsUnsubsPage({
        pid: this.$route.query.pid,
        action,
      }).then(() => {
        if (action === 'subs') {
          this.pageInfo.haveSubsed = true;
          this.$toast({
            message: '订阅成功～有新攻略时会通过系统消息提醒您哦',
            duration: 1000,
          });
        } else {
          this.pageInfo.haveSubsed = false;
        }
      });
    },
    sharePageAddExp() {
      if (this.shareBannerStatus === 1) {
        logger.info('sharePageAddExp');
        groupApi.sharePageAddExp({
          pid: this.$route.query.pid,
        }).then(() => {
          this.pageInfo.haveTodayShared = true;
          showTaskToast({
            title: '完成今日分享',
            experience: 10,
          });
        });
      }
    },
    onPostItemClick(postInfo) {
      logger.info('onPostItemClick');
      this.onRedDotClick(postInfo);
      uniOpenUrl(postInfo.schemeUrl);
    },
    onUnsubscribeClick() {
      logger.info('onUnsubscribeClick');
      this.unsubscribeDialogShow = true;
      this.reportData({
        moduleid: 80,
        eventtype: 3,
        extendid: 2,
      });
    },
    onUnsubscribeConfirm() {
      this.subsUnsubsPage('unsubs');
      this.reportData({
        moduleid: 80,
        eventtype: 3,
        extendid: 3,
        ext2: 2,
      });
    },
    onUnsubscribeCancel() {
      this.reportData({
        moduleid: 80,
        eventtype: 3,
        extendid: 3,
        ext2: 1,
      });
    },
    /** 获取攻略站已点击的新增二级模块列表(用于判断是否显示“新”的模块标志) */
    getCareerListClickNewSecondModuleIdList() {
      const userId = util.cookie.get('uid') || 0;
      const key = `career-list-click-second-module-id-list-${userId}`;
      const idList = (localStorage?.getItem(key))?.split(',') || [];

      return idList || [];
    },
    /** 设置攻略站已点击的新增二级模块列表(用于判断是否显示“新”的模块标志) */
    setCareerListClickNewSecondModuleIdList(redDotIdList) {
      const userId = util.cookie.get('uid') || 0;
      const key = `career-list-click-second-module-id-list-${userId}`;
      const value = (redDotIdList || []).join(',');
      localStorage.setItem(key, value);
    },
    /** 处理红点消息 */
    handleRedDot(redDot) {
      const originRedDotId = redDot?.id;
      const redDotIdList = originRedDotId?.split('@') || [];
      const redDotId = redDotIdList?.[Math.max(redDotIdList.length - 1, 0)] || '';
      const indexList = this.careerListIndexMap[redDotId];
      if (indexList?.length !== 3) {
        return;
      }

      const item = this.careerList[indexList[0]]?.[indexList[1]]?.[indexList[2]];
      if (item) {
        const hasRedDot = checkRedDotLight(redDot);
        // 记录下红点的完整id以及children，用于点消
        item.redDotId = originRedDotId;
        item.redDotChildren = redDot?.children || [];
        this.$set(item, 'hasRedDot', hasRedDot);
      }
    },
    handleRedDotUpdate() {
      if (!checkIsSupportRedDotQuery()) {
        return;
      }

      const toolBarItemId = this.$route.query.tool_bar_item_id;
      const nodeId = `ID_GROUP_ICON@${this.groupId}@${toolBarItemId}`;
      jsbridge.redDot.queryRedDotTree({ nodeId, depth: 4 })
        .then((res) => {
          const firstRedDotList = res?.children || [];
          for (const firstRedDot of firstRedDotList) {
            const secondRedDotList = firstRedDot?.children || [];
            // 处理二级模块的红点(ID_GROUP_SECOND@(二级模块)以及ID_GROUP_SINGLE_POST@(聚合帖子)两种类型)
            for (const secondRedDot of secondRedDotList) {
              this.handleRedDot(secondRedDot);
            }
          }
        });
    },
    /**
     * 处理新增二级模块“新”字显示的逻辑
     */
    handleShowSecondNewModule() {
      if (isServerRender || !this.careerList) {
        return;
      }

      const clickNewSecondModuleIdList = this.getCareerListClickNewSecondModuleIdList();
      this.careerList.forEach((item) => {
        if (!item?.l2List) {
          return;
        }

        item.l2List.forEach((detail) => {
          const { isNew = false, id = '' } = detail || {};
          const isNeverClick = !clickNewSecondModuleIdList.includes(id);
          const showNewFlag = Boolean(isNew && isNeverClick);
          if (showNewFlag) {
            this.$set(detail, 'showNewFlag', true);
          }
        });
      });
    },
    onNewSecondModuleClick(item) {
      if (!item?.showNewFlag) {
        return;
      }

      // 1.本地localStorage记录已点击过的“新”二级模块
      const clickRedDotIdList = this.getCareerListClickNewSecondModuleIdList();
      clickRedDotIdList.push(item.id);
      this.setCareerListClickNewSecondModuleIdList(clickRedDotIdList);
      this.$set(item, 'showNewFlag', false);
      // 2.同时点消当前二级模块下所有帖子节点的红点
      const redDotChildren = item?.redDotChildren || [];
      redDotChildren.forEach((child) => {
        const hasRedDot = checkRedDotLight(child);
        if (hasRedDot) {
          jsbridge.redDot.onRedDotClick({ nodeId: child.id });
        }
      });
    },
    onRedDotClick(item) {
      const { hasRedDot = false, id = '', redDotId = '' } = item || {};
      if (!id || !redDotId || !hasRedDot) {
        return;
      }

      jsbridge.redDot.onRedDotClick({ nodeId: redDotId });
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
