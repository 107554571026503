<template>
  <div class="com-author-hor">
    <Avatar
      :size="'s'"
      :avatar="avatar"
      class="user-info-head"
    />
    <span class="com-author-hor__nickname">{{ nickname }}</span>
    <span
      v-if="iconDecoType"
      :class="iconDecoType"
      class="com-author-hor__vip-ico"
    />
    <span
      v-if="readNum"
      class="com-author-hor__comment-num"
    >· {{ readNum }} 浏览</span>
  </div>
</template>
<script>

import Avatar from '@/ui/avatar/index.vue';

export default {
  name: 'AuthorHorizontal',
  components: { Avatar },
  props: {
    avatar: {
      type: String,
      required: true,
    },
    nickname: {
      type: String,
      required: true,
    },
    accountType: {
      type: Number,
      default: 0,
    },
    vipType: {
      type: Number,
      default: 0,
    },
    readNum: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {

    };
  },
  computed: {
    iconDecoType() {
      if (Number(this.vipType) === 1) {
        return {
          1: 'icon-v-blue', // 官方一级账号
          2: 'icon-v-blue', // 官方二级账号
          3: 'icon-star-blue', // 官方个人账号
          4: 'icon-v-orange', // 媒体企业
          5: 'icon-star-orange', // 媒体个人
        }[Number(this.accountType)] || '';
      }
      return '';
    },
  },
};
</script>
<style lang="scss" src="./scss/author-horizontal.scss"></style>
