<template>
  <div class="pc-share-bar">
    <div class="pc-share-bar-fixed pc-share-bar__row">
      <div class="pc-share-bar__left">
        <Avatar :avatar="avatar" />
      </div>
      <div class="pc-share-bar__center">
        <div class="pc-share-bar__desc">
          好友{{ nickname }}为你推荐
        </div>
        <div class="pc-share-bar__title">
          腾讯游戏官方社区超全攻略站
        </div>
      </div>
      <div class="pc-share-bar__right">
        <Button
          :width="60"
          @click="onBtnClick"
          type="primary"
          size="m"
        >
          打开
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/ui/avatar/index.vue';
import Button from '@/ui/button';
import lauchApp from '@/util/launchApp';

export default {
  name: 'PCShareBar',
  components: {
    Avatar, Button,
  },
  props: {
    avatar: {
      type: String,
      default: '',
    },
    nickname: {
      type: String,
      default: '',
    },
    channel: {
      type: String,
      default: '',
    },
  },
  methods: {
    onBtnClick() {
      lauchApp({
        channel: this.channel,
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/ui/style/variable.scss';
@import '~@/ui/style/mixin.scss';


.pc-share-bar {
  height: 100px; // 滚动占位用，使页面能滚动到最底部
  &-fixed {
    position: fixed;
    z-index: 10;
    bottom: 0;
    width: 100%;
    padding: 13px 18px;
    background-color: #ffffff;
    box-sizing: border-box;
    @include safe-bottom(padding-bottom, 13px);
  }
  .pc-share-bar__row {
    display: flex;
    flex-direction: row;
    .pc-share-bar__center {
      flex: 1;
      padding: 0 10px;
    }
  }
  .pc-share-bar__desc {
    font-size: 12px;
    color: $color-text-light;
  }
  .pc-share-bar__title {
    font-size: 14px;
    color: $color-title;
  }
}
</style>
