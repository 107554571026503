<template>
  <Button
    :type="buttonType"
    :icon="buttonIcon"
    :width="53"
    @click="$checkAuthDeco(onSubscribeButtonClick)"
    size="s"
    style="padding: 7px"
  >
    {{ buttonText }}
  </Button>
</template>

<script>
import Button from '@/ui/button';

/** 订阅按钮组件 */
export default {
  name: 'SubscribeButton',
  components: {
    Button,
  },
  props: {
    hasSubsribed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonType() {
      return this.hasSubsribed ? 'default' : 'primary';
    },
    buttonIcon() {
      return this.hasSubsribed ? '' : 'plus';
    },
    buttonText() {
      return this.hasSubsribed ? '已订阅' : '订阅';
    },
  },
  methods: {
    onSubscribeButtonClick() {
      this.$emit('subscribeButtonClick');
    },
  },
};

</script>
