import groupApi from '@/api/group';
// import { formatPreData } from '@/util/webbundle/predata-formater';
// import { logger } from '@/util/logger';
// import { isServerRender } from '@/util/env';
import { logger } from '@/util/logger';


export const PAGE_STORE_MODULE_NAME = 'career-list';


const createState = () => ({
  pageInfo: null,
  pageLoading: true,
  pageError: false,
});

export const pageStore = {
  namespaced: true,
  state: createState,
  mutations: {
    setPageInfo(state, pageInfo) {
      state.pageInfo = {
        ...state.pageInfo,
        ...pageInfo,
      };
    },
    setPageLoading(state, loading) {
      state.pageLoading = loading;
    },
    setPageError(state, pageError) {
      state.pageError = pageError;
    },
  },
  actions: {
    initPageData({ commit }, {
      pid,
      bandType,
      bandId,
    }) {
      if (!pid) {
        return Promise.reject();
      }
      return groupApi.getGroupToolCareerList({
        pid,
        bandType,
        bandId,
      }).then((result) => {
        this.pageLoading = false;
        commit('setPageLoading', false);
        if (result.ret === 0) {
          commit('setPageInfo', result.pageInfo);
        } else if (result.ret === 260003) {
          commit('setPageError', '对不起，该页面已下架');
        } else {
          commit('setPageError', result.msg || '服务器繁忙，请稍后重试');
        }
      })
        .catch((err) => {
          logger.err('getGroupToolCareerList err', err);
          commit('setPageLoading', false);
          commit('setPageError', '服务器繁忙，请稍后重试');
        });
    },
  },
};


export const pageMixin = {
  //
};
