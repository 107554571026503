<!-- eslint-disable vue/valid-template-root -->
<template>
  <PostLoading v-if="pageLoading" />
  <PostError
    v-else-if="pageError"
    :custom-header="false"
    :text="pageError"
    @buttonClick="reloadPage"
    button-title="刷新一下"
    status="fail"
  />
  <PostError
    v-else-if="!pageError && !roleList.length"
    :custom-header="true"
    @buttonClick="jumpBindRole"
    class="page-role-list-error"
    text="绑定后，可以查看角色资料"
    button-title="绑定角色"
    status="empty"
  >
    <template v-slot:header-left>
      <span v-if="!env.isApp" />
    </template>
    <template v-slot:header-title>
      角色卡
    </template>
    <template v-slot:header-right>
      <span />
    </template>
  </PostError>
  <div
    v-else
    class="page-role-list"
  >
    <PostHeader>
      <template v-slot:left-btn>
        <span v-if="!env.isApp" />
      </template>
      <template v-slot:right-btn>
        <span
          @click="roleManage"
          class="role-manage-button"
        >{{
          env.isApp ? "角色管理" : ""
        }}</span>
      </template>
      {{ env.isApp ? '' : `${nickName}的DNF手游` }}角色卡
    </PostHeader>
    <div class="page-role-detail">
      <div class="role-list-item swiper-container">
        <ul class="role-list-item-list swiper-wrapper">
          <li
            v-for="item in roleList"
            :key="item.roleid"
            class="swiper-slide"
          >
            <div class="role-list-item-list__box">
              <div class="role-list-item-list__img">
                <img :src="item.avatar">
              </div>
              <div class="role-list-item-list__content">
                <div class="role-list-item-list__title">
                  <h3>{{ item.name }}</h3>
                  <span
                    v-if="env.isApp"
                    @click="shareRoleCard(item)"
                    class="role-list-item-list__share"
                  />
                </div>
                <div class="role-list-item-list__subtitle">
                  <span class="role-list-item-list__leve">LV.{{ item.level }}</span>
                  <!-- <span v-for="(detail,index) in item.commDesc" :key="index">
                    {{ index ? ' · ' : '' }}{{ detail }}</span> -->
                  <span>{{ item.commDesc }}</span>
                </div>
              </div>
            </div>
            <div
              v-if="item.roleBigIcon"
              class="role-list-item-list__card-img"
            >
              <img :src="item.roleBigIcon">
            </div>
          </li>
        </ul>
        <div class="role-list-pagination swiper-pagination">
          <span
            v-for="i in roleList.length"
            :key="i"
            :class="activeSlideIndex === i - 1 ? 'swiper-pagination-bullet-active' : ''"
            class="swiper-pagination-bullet"
          />
        </div>
        <div
          v-if="activeSlideItem"
          :class="swiperSlideChange ? 'show' : ''"
          class="role-list-item-detail__value"
        >
          <h2>{{ activeSlideItem.fight }}</h2>
          <span>抗魔值</span>
        </div>
        <div
          v-for="(item,index) in equData"
          :key="index"
          :class="[`position-${index}`, index > 5 ? 'right' : '', swiperSlideChange ? 'show' : '']"
          class="role-list-item-detail__equ"
        >
          <img
            v-if="item.icon"
            :src="item.icon"
            @error="resetErrorImg(item)"
          >
          <span
            v-if="Number(item.upgradeLevel)"
            class="role-list-item-detail__equ-level"
          >等级{{ item.upgradeLevel }}</span>
        </div>
      </div>

    <!-- <div class="role-list-item-detail__dress" /> -->
    <!-- <div class="role-info-type">
      <div class="role-info-type__equip">
        装备
      </div>
    </div> -->
    </div>
    <ShareDownload
      v-if="!env.isApp"
    />
  </div>
</template>

<script>
import cookie from 'js-cookie';
import PostLoading from '@/component/post-loading/index.vue';
import PostError from '@/component/post-error/index.vue';
import PostHeader from '@/component/post-header/index.vue';
import ShareDownload from '@/component/share-download/index.vue';
import groupApi from '@/api/group';
import UserApi from '@/api/user';
import reportApi from '@/api/report';
import { checkEnv } from '@/util/browser-env';
import {
  openUrl, openView, showShareDialog, receiveGlobalNotification,
} from '@/jsbridge';
import launchApp from '@/util/launchApp';
import { appendParam } from '@/util/util';
import Swiper from 'swiper';

export default {
  name: 'MdnfRoleList',
  components: {
    PostLoading,
    PostError,
    PostHeader,
    ShareDownload,
  },
  data() {
    return {
      pageLoading: true,
      pageError: false,
      env: checkEnv(),
      roleList: [],
      swiper: null,
      swiperSlideChange: false,
      activeSlideItem: null,
      activeSlideIndex: 0,
      loadingStatus: false,
      nickName: '',
    };
  },
  provide() {
    return {
      reportData: this.reportData,
    };
  },
  computed: {
    equData() {
      const value = this.activeSlideItem;
      if (value) {
        if (!value.isGetEqu) {
          this.getEquDataById(value.roleid);
        }
        const arr = Array(11).fill(null)
          .map(() => ({
            equipments: [],
            isGetEqu: false,
          }));
        value.equipments.forEach((v) => {
          arr[v.position] = Object.assign(arr[v.position], v);
        });
        return arr;
      }
      return [];
    },
  },

  mounted() {
    // const VConsole = require('vconsole');
    // // eslint-disable-next-line no-unused-vars
    // const vConsole = new VConsole();
    if (this.env.isApp) {
      // 注册监听
      this.receiveNotice();
      this.$store.dispatch('user/getUserInfo').then(() => {
        if (this.$store.state.user.userInfo) {
          this.getPageData(true);
        } else {
          this.$store.dispatch('user/login').then(() => {
            if (this.$store.state.user.userInfo) {
              this.getPageData(true);
            } else {
              this.pageLoading = false;
              this.pageError = '您还未登录，请刷新页面重新登录';
            }
          })
            .catch(() => {
              this.pageLoading = false;
              this.pageError = '您还未登录，请刷新页面重新登录';
            });
        }
      })
        .catch((err) => {
          this.$logger.err('getUserInfo', err);
        });
    } else {
      this.getPageData(true);
    }
  },
  methods: {
    resetErrorImg(item) {
      const target = this.activeSlideItem.equipments.find(v => v.icon === item.icon);
      if (target) {
        target.icon = '';
      }
      return {
        ...item,
        icon: '',
      };
    },
    // 数据上报
    reportData(params, desc) {
      return reportApi({
        pageid: '1307',
        moduleid: '00001',
        ...params,
      }, desc);
    },
    reloadPage() {
      window.location.reload();
    },
    shareRoleCard(item) {
      this.reportData({
        moduleid: '00025',
        eventtype: '03',
        extendid: '01',
      }, '分享按钮点击');
      let { href } = window.location;
      href = appendParam(href, {
        active: item.roleid,
        id: this.$store.state.user.userInfo.uid,
        open_id: this.$store.state.user.userInfo.openId,
      });
      showShareDialog({
        title: `【${this.nickName}】的【${item.jobName}】，抗魔值达${item.fight}！点击查看我的配装`,
        summary: '最实时DNF手游角色查询，最全攻略尽在腾讯游戏社区！',
        targetUrl: href,
        thumbUrl: 'https://static.gameplus.qq.com/business/Share/iOSAPPicon100.png',
      }).then((result) => {
        if (result.result === 0) {
          this.reportData({
            moduleid: '00025',
            eventtype: '06',
            extendid: '01',
            ext1: {
              WXSession: '1',
              WXTimeline: '2',
              QQFriend: '3',
              QQZone: '4',
            }[result.data.type],
          }, '分享成功');
        }
      });
    },
    jumpBindRole() {
      const url = 'tgc://native?moduleName=dnf_add_role&gameCode=mdnf';
      if (this.env.isApp) {
        openView({
          options: url,
        });
      } else {
        launchApp({
          scheme: url,
        });
      }
    },
    roleManage() {
      this.reportData({
        moduleid: '00057',
        eventtype: '03',
        extendid: '01',
      }, '角色管理按钮点击');
      const url = 'tgc://native?moduleName=dnfrole&gameCode=mdnf&needClosePage=true';
      if (this.env.isApp) {
        openView({
          options: url,
        });
      } else {
        launchApp({
          scheme: url,
        });
      }
    },
    getPageData(isNeedReport = false) {
      let { id, open_id: openId } = this.$route.query;
      openId = openId === 'undefined' ? '' : openId; // 某些情况下会出现undefined
      if (this.env.isApp) {
        if (!this.$store.state.user.userInfo) {
          this.pageLoading = false;
          return;
        }
        id = this.$store.state.user.userInfo.uid;
        // eslint-disable-next-line prefer-destructuring
        openId = cookie.get('openId');
      }
      if (!id) {
        this.pageLoading = false;
        return;
      }
      Promise.all([
        this.getMDnfRoleCard(id, openId, isNeedReport),
        UserApi.getPersonalDetail({
          uid: id,
        }),
      ]).then((result) => {
        this.pageLoading = false;
        this.loadingStatus = false;
        if (result[0] && result[1].ret === 0) {
          this.nickName = result[1].detail.userInfo.nickName;
        } else {
          this.pageError = result.msg || '服务器繁忙，请稍后重试';
        }
        this.$nextTick(() => {
          this.initSwiper();
        });
      })
        .catch(() => {
          this.pageLoading = false;
          this.pageError = '服务器繁忙，请稍后重试';
        });
    },
    getMDnfRoleCard(uid, openId, isNeedReport) {
      return groupApi.getMDnfRoleCard({
        uid, openId,
      }).then((result) => {
        if (result.ret === 0) {
          this.roleList = result.roleInfos;
          [this.activeSlideItem] = this.roleList;
          if (isNeedReport) {
            this.reportData({
              eventtype: '01',
              extendid: '01',
              ext1: result.roleInfos.length ? '1' : '0',
            }, 'DNF角色卡页面PV');
          }
          return true;
        }
        this.pageError = result.msg || '服务器繁忙，请稍后重试';
        return false;
      });
    },
    getEquDataById(roleId) {
      let { id } = this.$route.query;
      if (this.env.isApp) {
        if (!this.$store.state.user.userInfo) {
          this.loadingStatus = false;
          return;
        }
        id = this.$store.state.user.userInfo.uid;
      }

      this.loadingStatus = true;
      groupApi.getMDnfEquDataByRoleId({
        uid: id,
        roleId,
      }).then((result) => {
        this.loadingStatus = false;
        if (result.ret === 0) {
          const target = this.roleList.find(v => v.roleid === roleId);
          target.equipments = result.equipments;
          target.isGetEqu = true;
        } else {
          this.pageError = result.msg || '服务器繁忙，请稍后重试';
        }
      })
        .catch(() => {
          this.pageLoading = false;
          this.pageError = '服务器繁忙，请稍后重试';
        });
    },
    initSwiper() {
      // 此处需要确保有数据才能初始化swiper，否则下一次初始化时会在destroy报错。
      if (this.swiper) {
        this.swiper.destroy(true, true);
        this.swiper = null;
      }
      if (!this.roleList.length) {
        return;
      }
      let { active = 0 } = this.$route.query;
      if (active) {
        active = decodeURIComponent(active);
        active = this.roleList.findIndex(v => v.roleid === active);
        active = active === -1 ? 0 : active;
      }
      const that = this;
      this.swiper = new Swiper('.swiper-container', {
        spaceBetween: 18,
        slidesPerView: 1.17,
        slidesOffsetBefore: 30,
        slidesOffsetAfter: 30,
        normalizeSlideIndex: false,
        initialSlide: active,
        observer: true,
        observeSlideChildren: true,
        centerInsufficientSlides: false,
        setWrapperSize: true,
        allowTouchMove: this.roleList.length > 1,
        // 此处当设置了slidesOffsetBefore和slidesOffsetAfter时，pagination的数量会计算不准确，此处自己手动生成pagination替代
        // pagination: {
        //   el: '.swiper-pagination',
        // },
        on: {
          init() {
            that.activeSlideIndex = this.realIndex;
          },
          transitionStart() {
            that.swiperSlideChange = false;
          },
          transitionEnd() {
            that.swiperSlideChange = true;
          },
          slideChange() {
            that.activeSlideItem = that.roleList[this.realIndex];
            that.activeSlideIndex = this.realIndex;
            that.reportData({
              moduleid: '00057',
              eventtype: '04',
              extendid: '01',
              ext1: that.roleList.length,
            }, '角色滑动');
          },
        },
      });
    },
    jumpToDetail(item) {
      let { url } = item;
      if (!url) {
        url = `${window.location.origin}/group/tool/career-detail?id=${item.id}`;
      }
      openUrl({ url });
    },
    receiveNotice() {
      if (this.$compareVersion('1.3') >= 0) {
        receiveGlobalNotification('dnf_add_role', () => {
          this.getPageData();
        });
        receiveGlobalNotification('dnf_del_role', () => {
          this.getPageData();
        });
      }
    },
  },
};
</script>

<style lang="scss" src="./scss/mdnf.scss"></style>
