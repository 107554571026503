/**
 * 圈子API
 * */
import { gameplusApi } from '@/util/http';

export default {
  /**
   * 通过groupId获取圈子信息
   * @param { Object } params
   * @param { Number } params.groupId 圈子ID
   * @return { Object } 返回获取结果
   * */
  getGroupInfoByGroupId({ groupId } = {}) {
    return gameplusApi.request({
      url: '/community.GroupSrv/GetGroupDetail',
      data: {
        groupId,
        fromPage: true,
      },
    });
  },

  /**
   * 拉取圈子详情页feed列表
   * @param { Object } params
   * @param { Number } params.groupId
   * @param { Number } type 圈子详情页tabid：0全部，1精华，2官方，3问答...
   * @param { Number } sortType 排序类型： 1最新回复，2最新发布，3智能排序
   * @returns { Object } 返回获取结果
   */
  getGroupFeedList({ groupId, type = 1, sortType = 2 }) {
    return gameplusApi.request({
      url: '/community.GroupSrv/GetGroupFeedList',
      data: {
        group_id: groupId,
        type,
        sort_type: sortType,
        page: 1,
        page_context: '',
        question_page_context: '',
      },
    });
  },

  /**
   * 拉取圈子工具栏的职业列表信息
   * @param { Object } params
   * @param { Number } params.pid
   * @return { Object } 返回获取结果
   * */
  getGroupToolCareerList({
    pid,
    bandType,
    bandId,
  } = {}) {
    return gameplusApi.request({
      url: '/community.GameToolSrv/GetGTPageInfo',
      data: {
        pid,
        band_type: bandType,
        band_id: bandId,
      },
    });
  },

  /**
   * 拉取圈子工具栏的职业详情
   * @param { Object } params
   * @param { Number } params.groupId 圈子ID
   * @return { Object } 返回获取结果
   * */
  getGroupToolCareerDetail({
    id,
    page,
    size,
  } = {}) {
    return gameplusApi.request({
      url: '/community.GameToolSrv/GetGTPostList',
      data: {
        l2_id: id,
        page_size: size,
        page,
      },
    });
  },

  /**
   * 拉取MDNF的角色列表
   * @param { Object } params
   * @param { Number } params.groupId 圈子ID
   * @return { Object } 返回获取结果
   * */
  getMDnfRoleCard({
    uid,
    openId,
  } = {}) {
    return gameplusApi.request({
      url: '/community.GCRoleSrv/GetMDnfRoleCard',
      data: {
        uid,
        openid: openId,
      },
    });
  },

  /**
   * 根据角色Id拉取角色的装备列表
   * @param { Object } params
   * @param { Number } params.groupId 圈子ID
   * @return { Object } 返回获取结果
   * */
  getMDnfEquDataByRoleId({
    uid,
    roleId,
  } = {}) {
    return gameplusApi.request({
      url: '/community.GCRoleSrv/GetMDnfEquipment',
      data: {
        uid,
        roleid: roleId,
      },
    });
  },

  /**
   * 工具页面页面订阅与取消订阅
   * @param {Object} param
   * @param {Number} param.pid 页面id
   * @param {String} param.action 动作:subs 订阅 unsubs 取消订阅
   * @param {String} param.operid operid枚举值，0表示攻略站，1表示福利站
   */
  subsUnsubsPage({ pid, action, operid = '0' }) {
    return gameplusApi.request({
      url: '/community.GameToolSrv/SubsUnsubsPage',
      data: {
        pid,
        action,
        operid,
      },
    });
  },

  /**
   * 当日首次分享加经验值
   * @param {Object} param
   * @param {Number} param.pid 页面id
   */
  sharePageAddExp({
    pid,
  }) {
    return gameplusApi.request({
      url: '/community.GameToolSrv/SharePageAddExp',
      data: {
        pid,
      },
    });
  },
};
